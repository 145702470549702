import pathMatch from 'path-match';

import {
	getProjectSearchRoutes,
	searchRoutesApi,
} from '../config/searchRoutes.config';
import { SearchState, SearchRoutesParams } from '../types/searchParams.types';
import { getProjectFacets } from '../config/searchFacets.config';

export const getSearchState = (pathname: string): SearchState => {
	const facets = getProjectFacets();

	const defaultState: SearchState = {
		term: '',
		facetSlug: facets[0].slug,
		page: 1,
	};

	const apiMatcher = pathMatch({ end: false })<SearchRoutesParams>(
		searchRoutesApi
	);
	const apiMatch = apiMatcher(pathname);
	const projectSearchRoutes = getProjectSearchRoutes();
	const projectMatcher = pathMatch({ end: false })<SearchRoutesParams>(
		projectSearchRoutes
	);
	const projectMatch = projectMatcher(pathname);

	const match = apiMatch || projectMatch;

	if (match) {
		const { facetSlug, term, page } = match;

		const state: SearchState = {
			term: term ?? defaultState.term,
			facetSlug:
				facetSlug != null && facets.some(({ slug }) => slug === facetSlug)
					? facetSlug
					: defaultState.facetSlug,
			page: Number(page ?? defaultState.page),
		};

		return state;
	}

	return defaultState;
};
