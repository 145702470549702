'use client';

import { pushSearchResultEvent } from '@/globals/analytics/events/pushSearchEvent';
import { getSearchState } from '@/globals/dataCollector/searchDataCollector/functions/getSearchState';
import { getSearchURL } from '@/globals/dataCollector/searchDataCollector/functions/getSearchURL';
import { Translation } from '@/globals/translate/translations';
import { searchIcon } from '@/uiIcons/searchIcon.css';
import { classes } from '@/uiPrimitives/base/classes.helpers';
import { box } from '@/uiPrimitives/layout/box';
import { indentSize } from '@/uiPrimitives/layout/indentSize.definitions';
import { occupy } from '@/uiPrimitives/layout/occupy';
import { shelf } from '@/uiPrimitives/layout/shelf';
import { zIndex } from '@/uiPrimitives/layout/zIndex.css';
import { icon } from '@/uiPrimitives/media/icon';
import { textBlock } from '@/uiPrimitives/typography/text';
import { hiddenAccessible } from '@/uiPrimitives/utility/display.css';
import { useRouter } from 'next/navigation';
import { useId } from 'react';
import { useSearchHistory } from './useSearchHistory';
import { usePageHistory } from '@/globals/pageWrappers/usePageHistory';

export const IndependentSearchForm = ({
	label,
	submitText,
	className,
	defaultValue,
}: {
	label: Translation<'Search in {site}'>;
	submitText: Translation<'Search'>;
	defaultValue?: string;
	className?: string;
}) => {
	const id = useId();
	const { push } = useRouter();
	const searchHistory = useSearchHistory();
	const [pageHistory] = usePageHistory();

	return (
		<form
			action={(formData) => {
				const term = formData.get('term');

				if (typeof term !== 'string') return;

				const searchURL = getSearchURL({ term, page: 1, facetSlug: 'all' });
				const searchState = getSearchState(searchURL);

				push(searchURL);
				pushSearchResultEvent({
					searchState: searchState,
					searchHistory: searchHistory,
					pageHistory,
				});
			}}
			className={classes(className, zIndex({ isolation: 'isolate' }))}
		>
			<label htmlFor={id} className={classes(hiddenAccessible)}>
				{label}
			</label>
			<div
				className={classes(
					shelf({ gridTemplateColumns: 'minmax(0, 1fr) auto' })
				)}
			>
				<input
					type="text"
					placeholder={label}
					name="term"
					id={id}
					defaultValue={defaultValue}
					className={classes(
						textBlock({}),
						box({
							paddingBlock: '3 | h1',
							paddingInline: indentSize,
							backgroundColor: 'negative',
							borderWidth: '1px',
							borderStyle: 'solid',
							borderColor: 'positive',
						}),
						zIndex({
							'zIndex:focusVisible': '1',
						})
					)}
				/>
				<button
					type="submit"
					aria-label={submitText}
					className={classes(
						box({
							inlineSize: 'fit-content',
							padding: '3 | h1',
							backgroundColor: 'positive',
						})
					)}
				>
					<span
						className={classes(
							occupy({ inlineSize: 'capSize', blockSize: 'capSize' })
						)}
					>
						<i
							className={classes(
								icon({ icon: searchIcon, blockSize: '3 | h1' })
							)}
						/>
					</span>
				</button>
			</div>
		</form>
	);
};
